import { FeatureFlagged } from '@backstage/core-app-api';
import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import React from 'react';
import { defaultEntityPage } from './ComponentPageTypes/DefaultEntityPage';
import { ServiceEntityPage } from './ComponentPageTypes/ServiceEntityPage';
import { WebsiteEntityPage } from './ComponentPageTypes/WebsiteEntityPage';
import { DocumentationEntityPage } from './ComponentPageTypes/DocumentationEntityPage';

export const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType(['service', 'infra-service'])}>
      <FeatureFlagged with="entity-nav-dropdown">
        <ServiceEntityPage featureFlagged />
      </FeatureFlagged>
      <FeatureFlagged without="entity-nav-dropdown">
        <ServiceEntityPage />
      </FeatureFlagged>
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      <FeatureFlagged with="entity-nav-dropdown">
        <WebsiteEntityPage featureFlagged />
      </FeatureFlagged>
      <FeatureFlagged without="entity-nav-dropdown">
        <WebsiteEntityPage />
      </FeatureFlagged>
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('documentation')}>
      <DocumentationEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
