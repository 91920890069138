import { FeatureFlagged } from '@backstage/core-app-api';
import {
  EntityProvidedApisCard,
  EntityConsumedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityLayout,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasSubcomponentsCard,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  isKubernetesAvailable,
  EntityKubernetesContent,
} from '@backstage/plugin-kubernetes';
import { EntityCortexContent } from '@cortexapps/backstage-plugin';
import {
  EntityAnimalsDeploymentsContent,
  isAnimalsProjectIDAnnotationAvailable,
} from '@efg/plugin-animals-deploy';
import {
  TechdocRoadmapsAndGoldenpathViewerPage,
  isRoadmapAnnotationAvailable,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';
import {
  EntityGitlabContent,
  isGitlabAvailable,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import { EntityIncidentCard } from '@incident-io/backstage';
import { Grid } from '@material-ui/core';
import {
  isKubecostAvailable,
  BackstagePluginKubecostPage,
} from '@suxess-it/backstage-plugin-kubecost';
import React from 'react';
import { CicdContent } from '../../../EntityContent/CicdContent';
import { overviewContent } from '../../../EntityContent/DefaultOverviewContent';
import { techdocsContent } from '../../../EntityContent/TechdocsContent';
import { entityRoutes } from '../../../EntityNavDropdown/EntityDropdownRoutes';

export const ServiceEntityPage = ({ featureFlagged }: any) => {
  const { entity } = useEntity();

  const hasSubcomponents = entity?.relations?.some(
    relation => relation.type === 'hasPart',
  );

  const dropdownNavEntityLayout = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/cortex" title="Cortex">
        <EntityCortexContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isKubernetesAvailable}
        path="/kubernetes"
        title="Kubernetes"
      >
        <EntityKubernetesContent refreshIntervalMs={30000} />
      </EntityLayout.Route>

      <FeatureFlagged with="kubecost">
        <EntityLayout.Route
          if={isKubecostAvailable}
          path="/kubecost"
          title="Cost"
        >
          <BackstagePluginKubecostPage />
        </EntityLayout.Route>
      </FeatureFlagged>
      {entityRoutes(
        [
          {
            path: '/incidents',
            title: 'Incidents',
            content: (
              <Grid item md={6}>
                <EntityIncidentCard />
              </Grid>
            ),
          },
          {
            path: '/ci-cd',
            title: 'CI/CD',
            content: <CicdContent />,
          },
          {
            path: '/repo-info',
            title: 'Repo Info',
            content: <EntityGitlabContent />,
          },
          {
            path: '/animals-cd',
            title: 'Deploy History',
            content: <EntityAnimalsDeploymentsContent />,
            shouldRender: isAnimalsProjectIDAnnotationAvailable(entity),
          },
          {
            path: '/api',
            title: 'APIs',
            content: (
              <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                  <EntityProvidedApisCard />
                </Grid>
                <Grid item md={6}>
                  <EntityConsumedApisCard />
                </Grid>
              </Grid>
            ),
          },
          {
            path: '/dependencies',
            title: 'Dependencies',
            content: (
              <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                  <EntityDependsOnComponentsCard variant="gridItem" />
                </Grid>
                <Grid item md={6}>
                  <EntityDependsOnResourcesCard variant="gridItem" />
                </Grid>
              </Grid>
            ),
          },
          {
            path: '/roadmap',
            title: 'Roadmap',
            content: <TechdocRoadmapsAndGoldenpathViewerPage />,
            shouldRender: isRoadmapAnnotationAvailable(entity),
          },
          {
            path: '/relations',
            title: 'Relations',
            content: (
              <>
                <Grid item md={12} xs={12}>
                  <EntityCatalogGraphCard variant="gridItem" height={300} />
                </Grid>
                {hasSubcomponents && (
                  <Grid item md={8} xs={12}>
                    <EntityHasSubcomponentsCard variant="gridItem" />
                  </Grid>
                )}
              </>
            ),
          },
        ].filter(route => route.shouldRender !== false),
      )}
    </EntityLayout>
  );

  const tabsNavEntityLayout = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/cortex" title="Cortex">
        <EntityCortexContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/incidents" title="Incidents">
        <Grid item md={6}>
          <EntityIncidentCard />
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/ci-cd" title="CI/CD">
        <CicdContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isGitlabAvailable}
        path="/repo-info"
        title="Repo Info"
      >
        <EntityGitlabContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/animals-cd"
        title="Deploy History"
        if={isAnimalsProjectIDAnnotationAvailable}
      >
        <EntityAnimalsDeploymentsContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/api" title="APIs">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityProvidedApisCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumedApisCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isKubernetesAvailable}
        path="/kubernetes"
        title="Kubernetes"
      >
        <EntityKubernetesContent refreshIntervalMs={30000} />
      </EntityLayout.Route>

      <FeatureFlagged with="kubecost">
        <EntityLayout.Route
          if={isKubecostAvailable}
          path="/kubecost"
          title="Cost"
        >
          <BackstagePluginKubecostPage />
        </EntityLayout.Route>
      </FeatureFlagged>

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isRoadmapAnnotationAvailable}
        path="/roadmap"
        title="Roadmap"
      >
        <TechdocRoadmapsAndGoldenpathViewerPage />
      </EntityLayout.Route>

      <EntityLayout.Route path="/relations" title="Relations">
        <>
          <Grid item md={12} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={300} />
          </Grid>
          {hasSubcomponents && (
            <Grid item md={8} xs={12}>
              <EntityHasSubcomponentsCard variant="gridItem" />
            </Grid>
          )}
        </>
      </EntityLayout.Route>
    </EntityLayout>
  );

  return featureFlagged ? dropdownNavEntityLayout : tabsNavEntityLayout;
};
