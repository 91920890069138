import {
  OpaMetadataAnalysisCard,
  hasOPAValidationErrors,
} from '@parsifal-m/plugin-opa-entity-checker';
import {
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import { FeatureFlagged } from '@backstage/core-app-api';

import React from 'react';

export const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <FeatureFlagged with="entity-check">
      <EntitySwitch>
        <EntitySwitch.Case if={hasOPAValidationErrors}>
          <Grid item xs={12}>
            <OpaMetadataAnalysisCard
              title="Entity Validation"
              variant="compact"
            />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </FeatureFlagged>
  </>
);
